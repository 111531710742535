@import "../../../sass/main.scss";

%mainNavigationLinkRules {
  display: block;
  width: 100%;
  border-radius: 5px;
  padding: 5px 0;
  color: black;
  background-color: rgba($color: #000000, $alpha: 0);
  text-align: center;
  font-size: 19px;
  font-weight: 500;
  word-wrap: break-word;

  @include mq(tablet) {
    height: min-content;
    width: fit-content;
    border-radius: 3px 3px 0 0;
    font-size: 15px;
    transition: background-color linear 100ms, color linear 100ms;
  }
}

.linksList {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;

  @include mq(tablet) {
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 0;
  }

  .linksListItem {
    width: 95vw;
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }

    @include mq(tablet) {
      margin-top: 0;
      margin-right: 10px;
      margin-bottom: 0;
      width: fit-content;

      &:first-of-type {
        margin-top: inherit;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    .activeLink {
      @extend %mainNavigationLinkRules;
      color: white;
      background-color: var(--navigationBlueColor);
      width: fit-content;
      margin: 0 auto;
      padding: 5px 50px;

      @include mq(tablet) {
        padding: 4px 7px;
      }
    }

    .inactiveLink {
      @extend %mainNavigationLinkRules;

      @include mq(mobile-only) {
        border-radius: 0;
      }

      @include mq(tablet) {
        color: var(--navigationBlueColor);
        background-color: white;
        padding: 2px 7px;
        margin-top: 4px;
      }
    }
  }
}
