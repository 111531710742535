@import "../../sass/main.scss";

.wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  padding-left: 3vw;
  z-index: 2;
  border-bottom: 3px solid #669766;
  background: var(--mainBackgroundGradientMobile);
  @include mq(tablet) {
    background: var(--mainBackgroundGradientTablet);
    border-bottom-color: #5d905d;
  }
  @include mq(desktop) {
    background: var(--mainBackgroundGradientDesktop);
    border: 0;
    padding: 20px 40px;
  }

  .headerLogoLink {
    margin-right: auto;

    .headerLogo {
      min-height: 45px;
      height: 6.5vw;
      @include mq(desktop) {
        height: 6vw;
      }
    }
  }

  .gifBlock {
    display: none;
    position: relative;
    margin: 5px 0;
    @include mq(tablet) {
      display: block;
    }

    @include mq(desktop) {
      margin: 0;
    }

    .gifWrapper {
      position: relative;
      height: 9vw;
      min-height: 70px;
      @include mq(desktop) {
        height: 12vw;
      }
      .gif {
        height: 100%;
        border-radius: 5px;
      }
    }

    .playGifBtn {
      position: absolute;
      display: flex;
      align-items: center;
      top: 20%;
      right: 2%;
      padding: 0;
      border-radius: 7px;
      border: 0;
      font-size: 10px;
      font-weight: 500;
      color: white;
      text-shadow: 0px 0px 3px black;
      background-color: rgba(0, 0, 0, 0.3);
      transform: translateY(-50%);

      @include mq(desktop) {
        top: 15%;
        &:hover {
          .playGifIcon {
            fill: #eb9700;
          }
        }
      }

      .playGifIcon {
        margin-left: 5px;
        height: 17px;
        width: 17px;
        transition: fill linear 200ms;
      }
    }
  }

  .toggleMenuButton {
    background-color: var(--mainBackgroundColor);
    border: 0;
    padding: 0;
    width: 8vw;
    min-width: 70px;
    height: 60px;
    z-index: 99;

    @include mq(tablet) {
      min-height: 80px;
      height: calc(9vw + 10px);
    }

    @include mq(desktop) {
      display: none;
    }

    .toggleMenuButtonIcon {
      width: 40%;
    }
  }
}
