@import "../../../sass/main.scss";

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 100%;
  height: 100%;

  &:after {
    display: block;
    position: absolute;
    content: "";
    top: calc(50% - 35px);
    left: calc(50% - 35px);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 6px solid rgb(0, 0, 0);
    border-color: rgb(81, 0, 255) transparent rgb(255, 213, 0) transparent;
    animation: wrapper 1.2s linear infinite;

    @include mq(desktop) {
      top: calc(50% - 45px);
      left: calc(50% - 45px);
      width: 90px;
      height: 90px;
      border-width: 8px;
    }
  }

  @keyframes wrapper {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
