%headerListButtonRules {
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color linear 200ms;
  padding: 5px 0;
  width: 95vw;
  background-color: rgba($color: #000000, $alpha: 0);
  font-size: 20px;
  font-weight: 500;
  margin: 0 auto 13px auto;
  @include mq(tablet) {
    font-size: 22px;
    margin-bottom: 30px;
  }

  svg.headerMenuListBtnIcon {
    height: 15px;
    width: 15px;
    transition: all linear 200ms;

    &:first-of-type {
      margin-right: 25px;
    }
    &:last-of-type {
      margin-left: 25px;
    }
  }
}

%headerListItemRules {
  transition: all linear 300ms;
}

%headerListLinkRules {
  position: relative;
  display: block;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  font-size: 15px;
  font-weight: 500;
  transition: all linear 300ms;
  @include mq(tablet) {
    font-size: 16px;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 115%;
    max-width: 95vw;
    transform: translateX(-50%);
    outline: 1px solid;
  }

  img.headerMenuListItemImg {
    transition: height linear 300ms;
    margin: 0 auto;
    width: auto;
    border-radius: 3px;
  }
}

button.headerMenuOpenListBtn {
  @extend %headerListButtonRules;
  color: var(--linksHoverColor);
  svg.headerMenuListBtnIcon {
    fill: var(--linksHoverColor);
    transform: rotateX(180deg);
  }
}

button.headerMenuClosedListBtn {
  @extend %headerListButtonRules;
  color: black;
  svg.headerMenuListBtnIcon {
    fill: #000000ba;
  }
}

ul.headerMenuList {
  width: 95vw;
  transition: margin-bottom linear 300ms;
  margin: -10px auto 0 auto;
  @include mq(tablet) {
    margin-top: -30px;
  }
  &:has(li.headerMenuListItemOpen) {
    margin-bottom: 20px;
  }

  li.headerMenuListItemOpen {
    @extend %headerListItemRules;
    opacity: 1;
    visibility: visible;
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
    a.headerMenuListItemLinkActive,
    a.headerMenuListItemLinkInactive {
      line-height: 1;
      padding: 7px 0 3px 0;
      img.headerMenuListItemImg {
        margin: 10px auto 0 auto;
        height: 80px;
      }
    }
  }

  li.headerMenuListItemClosed {
    @extend %headerListItemRules;
    opacity: 0;
    visibility: hidden;
    margin-bottom: 0;
    a.headerMenuListItemLinkActive,
    a.headerMenuListItemLinkInactive {
      line-height: 0;
      padding: 0;
      img.headerMenuListItemImg {
        height: 0;
      }
    }
  }

  a.headerMenuListItemLinkActive {
    @extend %headerListLinkRules;
    color: var(--linksHoverColor);
    &::after {
      outline-color: #0900d9b3;
    }
  }

  a.headerMenuListItemLinkInactive {
    @extend %headerListLinkRules;
    color: black;
    &::after {
      outline-color: #b6b6b6;
    }
  }
}
