:root {
  --mainBackgrColor: rgba(0, 0, 0, 0.03);
  --boldTextColor: #000000c7;
  --imageShadow: 0px 0px 14px 5px rgba(172, 172, 172, 0.3);
  --linksColor: #0900d9;
  --linksHoverColor: #000c7a;
  --navigationBlueColor: #00009fde;
  --mainTitleColor: #00177f;
  --mainBackgroundGradientMobile: linear-gradient(
    180deg,
    rgb(71 165 71) 0%,
    rgb(255, 255, 255) 45vh
  );
  --mainBackgroundGradientTablet: linear-gradient(
    180deg,
    rgb(71 165 71) 0%,
    rgb(255, 255, 255) 60vh
  );

  --mainBackgroundGradientDesktop: linear-gradient(
    180deg,
    rgb(10, 124, 10) 0%,
    rgb(255, 255, 255) 65vh
  );
}
