@import "../../../sass/main.scss";

.modalBodyBlock {
  overflow: auto;
  padding: 30px 0 50px 0;
  height: 100%;
  width: 100%;
  padding-top: 80px;
  transition: opacity linear 150ms, z-index linear 150ms;
  background: var(--mainBackgroundGradientMobile);
  @include mq(tablet) {
    background: var(--mainBackgroundGradientTablet);
    padding-top: max(calc(9vw + 43px), 112px);
  }
  @include mq(desktop) {
    display: none;
  }

  .listItemDisplayClass {
    @include mq(tablet) {
      display: none;
    }
  }
}
