%mainImageStyles {
  border-radius: 3px;
  box-shadow: var(--imageShadow);
}

%mainFontStyles {
  font-size: 15px;
  font-weight: 500;
  color: var(--boldTextColor);
  @include mq(tablet) {
    font-size: 16px;
  }
}

.articleMainTitle {
  font-size: 19px;
  text-align: center;
  font-weight: 600;
  color: var(--mainTitleColor);
  margin-bottom: 2vw;

  @include mq(tablet) {
    font-size: 22px;
  }
  @include mq(desktop-big) {
    margin-bottom: 20px;
    font-size: 24px;
  }
}

.articleSecondaryTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--mainTitleColor);
  margin-bottom: 2vw;
  @include mq(tablet) {
    font-size: 20px;
    margin-bottom: 10px;
  }
  @include mq(desktop-big) {
    margin: 20px 0 10px 0;
  }
}

.articleText {
  @extend %mainFontStyles;
  margin-bottom: 10px;
  &:last-of-type {
    margin-bottom: 0;
  }
  @include mq(tablet) {
    font-size: 16px;
  }
}

.articleBoldText {
  font-weight: 600;
  color: var(--boldTextColor);
}

.articleLink {
  @extend %mainFontStyles;
  width: fit-content;
  color: var(--linksColor);
  text-decoration: underline;
  transition: color linear 100ms, margin-left linear 100ms;
  &:hover {
    margin-left: 5px;
    color: var(--linksHoverColor);
  }
}

.articleQuote {
  @extend %mainFontStyles;
  margin: 15px 0;
  text-align: center;
  font-style: italic;
  @include mq(tablet) {
    font-size: 16px;
  }
}

.articleImage {
  @extend %mainImageStyles;
  margin: 20px auto;
  width: 90%;
  max-width: 320px;
}

.articleTextList {
  padding-left: 2px;
  margin: 10px 0 20px 0;
  list-style: disc;
  padding-left: 20px;

  .articleTextListItem {
    @extend %mainFontStyles;
    margin-bottom: 10px;
    @include mq(desktop-big) {
      margin-bottom: 15px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.articleImageList {
  margin: 20px auto;
  max-width: 740px;
  @include mq(tablet) {
    display: flex;
    justify-content: space-evenly;
    margin: 30px auto;
  }
  .articleImageListItem {
    width: fit-content;
    margin: 0 auto;
    width: 90%;
    max-width: 320px;
    @include mq(tablet) {
      margin: 0;
    }
    &:first-of-type {
      margin-bottom: 20px;
      @include mq(tablet) {
        margin: 0 20px 0 0;
      }
    }

    .articleListItemImage {
      @extend %mainImageStyles;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}
