@import "../../sass/main.scss";

.container {
  display: flex;
  flex-grow: 1;
  padding: 15px;
  padding-bottom: 50px;

  @include mq(tablet) {
    padding: 3vw;
    padding-bottom: 5vw;
  }
  @include mq(desktop) {
    padding: 10px;
    padding-bottom: 50px;
  }
  @include mq(desktop-big) {
    padding: 30px;
    padding-bottom: 50px;
  }

  .sidebarWrapper{
    display: none;
    @include mq(desktop) {
      display: block;
      width: 180px;
      flex-shrink: 0;
    }
    @include mq(desktop-big) {
      width: 200px;
    }
  }

  .centerContainer {
    width: 100%;
    @include mq(desktop) {
      padding: 0 30px;
    }
    @include mq(desktop-big) {
      padding: 0 40px;
    }

    .articleWrapper {
      position: relative;
      height: max-content;
      min-height: 100%;
    }
  }

  .mainNavigationBlock {
    display: none;
    @include mq(tablet) {
      display: flex;
      align-items: flex-end;
      margin-bottom: 2vw;
      border-bottom: 3px solid var(--navigationBlueColor);
      margin-top: -6px;
    }

    @include mq(desktop-big) {
      margin-bottom: 25px;
    }
  }
}
