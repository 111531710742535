@import "../../../sass/main.scss";

.modalBackdrop {
  display: flex;
  background-color: rgba($color: #000000, $alpha: 0.5);
  position: relative;
  height: 100vh;
  width: 100vw;
  cursor: zoom-out;

  .closeModalBtn {
    margin-left: auto;
    height: 80px;
    width: 80px;
    padding: 20px;
    background-color: rgba($color: #000000, $alpha: 0.1);
    box-shadow: 0px 0px 10px 7px rgba($color: #000000, $alpha: 0.1);
    border: 0;
    border-bottom-left-radius: 30px;
    transition: padding linear 150ms;
    &:hover {
      padding: 15px;
    }
  }

  .image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    max-width: 90vw;
    height: 70vh;
    object-fit: contain;
    border-radius: 4px;
    cursor: default;
    transform: translate(-50%, -50%);
  }
}
