@mixin mq($range) {
  $mobile: 470px;
  $tablet: 700px;
  $desktop: 1100px;
  $desktop-big: 1400px;

  @if $range == mobile-only {
    @media (max-width: #{$tablet - 0.02}) {
      @content;
    }
  } @else if $range == mobile {
    @media (min-width: $mobile) {
      @content;
    }
  } @else if $range == tablet-only {
    @media (min-width: $tablet) and (max-width: #{$desktop - 0.02}) {
      @content;
    }
  } @else if $range == tablet {
    @media (min-width: $tablet) {
      @content;
    }
  } @else if $range == desktop {
    @media (min-width: $desktop) {
      @content;
    }
  } @else if $range == desktop-big {
    @media (min-width: $desktop-big) {
      @content;
    }
  }
}
