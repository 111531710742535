@import "../../../sass/main.scss";

.text {
  width: fit-content;
  padding: 10px;
  margin: 0 auto;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: #f10000;
  background-color: rgba($color: #000000, $alpha: 0.04);
  box-shadow: 0px 0px 5px 10px rgba($color: #000000, $alpha: 0.03);
  border-radius: 50px;

  @include mq(tablet) {
    font-size: 19px;
  }
}
